/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Link } from 'gatsby';
import FooterFr from '../images/fr-footer.png';
import { useFooterMenuitems } from '../hooks/getFooterMenuItems';
import { useSocialLinks } from '../hooks/getSocialLinks';
import { useFooterContent } from '../hooks/getFooterContent';

function MenuItemLink(data) {
    if (data.type === 'custom') {
        return (
            <a href={data.path} target="_blank" rel="noopener noreferrer">
                {data.title}
            </a>
        );
    }
    if (data.type === 'post_type') {
        const postTypeUrl = data.url
            .split('https://werockthespectrumedmonton.wrtsfranchise.com/')
            .pop();
        return <Link to={`/${postTypeUrl}`}>{data.title}</Link>;
    }
}

const Footer = () => {
    const menuItems = useFooterMenuitems();
    const socialLinks = useSocialLinks();
    const footerContent = useFooterContent();
    return (
        <>
            <footer>
                <div className="footaddress">
                    <h2>
                        {footerContent.footer_address}
                        {` `}
                        <span className="ipadhide">|</span> 
                        {` `}
                        <span className="yellowtxt">
                            CALL US: {footerContent.footer_phone_number}
                        </span>
                    </h2>
                </div>

                <h3 className="footinst">
                    FOLLOW US ON INSTAGRAM {` `}
                    <a
                        className="yellowtxt"
                        href={socialLinks.social_instagram_link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        @WRTSEDMONTON
                    </a>
                </h3>

                <div className="footer-nav">
                    {menuItems.map(menuItem => (
                        <MenuItemLink
                            path={menuItem.url}
                            url={menuItem.url}
                            title={menuItem.title}
                            type={menuItem.type}
                            hasChildren={menuItem.wordpress_children}
                            key={menuItem.wordpress_id}
                        />
                    ))}
                </div>
              <div className="sign-box-up">
                <p className="sign-box-up-par"><span>JOIN OUR NEWSLETTER!</span><br />Subscribe to get the latest updates on events, classes, and more!</p>
                    <a
                        className="signup-letter-new"
                        href="https://axle.ws/3lx5P/subscribe/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >SUBSCRIBE</a>
                    </div>
                <div className="headersocial footsoc soc">
                    <a
                        className="soc-facebook"
                        href={socialLinks.social_facebook_link}
                        target="_blank"
                        rel="noopener noreferrer"
                    ></a>
                    <a
                        className="soc-instagram"
                        href={socialLinks.social_instagram_link}
                        target="_blank"
                        rel="noopener noreferrer"
                    ></a>
                     {/* <a
                        className="soc-pinterest"
                        href={socialLinks.social_pinterest_link}
                        target="_blank"
                        rel="noopener noreferrer"
                    ></a>  */}
                 {/* <a
                        className="soc-twitter"
                        href={socialLinks.social_twitter_link}
                        target="_blank"
                        rel="noopener noreferrer"
                    ></a>  */}
                    {/* <a
                        className="soc-yelp soc-icon-last"
                        href={socialLinks.social_yelp_link}
                        target="_blank"
                        rel="noopener noreferrer"
                    ></a> */}
                </div>
               
                <a
                    href="http://www.franchiseregistry.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img className="footerfr" src={ 'https://werockthespectrumkidsgym.wrtsfranchise.com/wp-content/uploads/2022/07/FRANdata_FranchiseRegistryMember_Logo_2022.png' } alt="footer fr" />
                </a>
            </footer>
        </>
    );
};
export default Footer;
